.footer {
  align-items: flex-start;
  background-color: var(--gray-900);
  display: flex;
  position: relative;
  margin-top: 24px;
}

.footer .logo-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer .logo-default-7 {
  background-color: var(--gray-900) !important;
  height: 42px !important;
  min-width: 110px !important;
  position: relative !important;
  width: unset !important;
}

.footer .copyrights {
  border-color: var(--gray-500);
  border-top-style: solid;
  border-top-width: 1px;
  height: 41px;
  min-width: 238px;
  position: relative;
}

.footer .copyrights-icon {
  height: 16px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 16px;
}

.footer .copyrights-2 {
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 12px;
  font-weight: 300;
  height: 17px;
  left: 16px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.footer .element-16 {
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .input-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;
}

.footer .base-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.footer .input-field-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.footer .input-field-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--gray-50);
  border-radius: 3px;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  position: relative;
}

.footer .txt {
  color: var(--gray-200);
  flex: 1;
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.6px;
  opacity: 0.7;
  position: relative;
  white-space: nowrap;
}

.footer .arrow-icon {
  height: 16px;
  min-width: 16px;
  position: relative;
}

.footer .arrow-icon-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray-50);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-left: -4px;
  padding: 10px 12px;
  position: relative;
  width: fit-content;
}

.footer .subscribe {
  display: flex;
  position: relative;
}

.footer .element-17 {
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: -36px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 336px;
}

.footer .logo-light {
  height: 42px;
  min-width: 97px;
  position: relative;
}

.footer .logo-default-8 {
  background-color: var(--gray-900) !important;
  height: 42px !important;
  width: 97px !important;
}

.footer .line {
  align-self: stretch;
  height: 42px;
  min-width: 1px;
  object-fit: cover;
  position: relative;
}

.footer .copy {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.footer .frame-3 {
  align-items: center;
  display: flex;
  gap: 2px;
  position: relative;
  width: fit-content;
}

.footer .vector-2 {
  height: 8.65px;
  min-width: 8.65px;
  position: relative;
}

.footer .copyrights-3 {
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer .copyrights-4 {
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.footer.is-mob-6-false {
  justify-content: space-between;
  padding: 32px 120px;
  width: 100%;
}

.footer.is-mob-6-true {
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  padding: 32px 24px;
}

.footer.is-mob-6-false .logo-2 {
  gap: 8px;
  justify-content: center;
  width: fit-content;
}

.footer.is-mob-6-true .logo-2 {
  gap: 20px;
  width: 300px;
}

.footer.is-mob-6-false .subscribe {
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 300px;
}

.footer.is-mob-6-true .subscribe {
  align-items: center;
  align-self: stretch;
  gap: 16px;
}
