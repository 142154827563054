.ismob-false-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.ismob-false-wrapper .bg-3 {
  background-color: var(--slate-100);
  border-radius: 4px;
  height: 300px;
  position: absolute;
}

.ismob-false-wrapper .bg-texture {
  position: absolute;
}

.ismob-false-wrapper .class {
  box-shadow: 0px 0px 8px #0000001a !important;
}

.ismob-false-wrapper .class-2 {
  margin-right: -2518px !important;
  margin-top: -4792px !important;
  object-fit: unset !important;
}

.ismob-false-wrapper .class-3 {
  min-width: 69px !important;
  object-fit: unset !important;
}

.ismob-false-wrapper .class-4 {
  margin-right: -3182px !important;
  margin-top: -4792px !important;
}

.ismob-false-wrapper .class-5 {
  margin-right: -2850px !important;
  margin-top: -4792px !important;
  object-fit: unset !important;
}

.ismob-false-wrapper .class-6 {
  flex: 1 !important;
  gap: unset !important;
  justify-content: space-between !important;
}

.ismob-false-wrapper .class-7 {
  box-shadow: 0px 4px 4px #00000040 !important;
  flex: 1 !important;
  gap: unset !important;
  justify-content: space-between !important;
}

.ismob-false-wrapper .class-8 {
  padding: 0px !important;
  width: 370px;
}

.ismob-false-wrapper .class-9 {
  margin-right: -350px !important;
}

.ismob-false-wrapper .class-10 {
  align-self: stretch !important;
  flex: 1 !important;
  /* width: unset !important; */
}

.ismob-false-wrapper .class-11 {
  align-self: stretch !important;
  box-shadow: 0px 0px 8px #0000001a !important;
  flex: 1 !important;
  /* width: unset !important; */
}

.ismob-false-wrapper .class-12 {
  gap: 64px !important;
  padding: 48px 24px !important;
  width: 390px !important;
}

.ismob-false-wrapper .class-13 {
  gap: 64px !important;
  padding: 36px 119px !important;
  max-width: 1480px !important;
}

.ismob-false-wrapper .class-14 {
  font-size: 20px !important;
  line-height: 28px !important;
}

.ismob-false-wrapper .class-15 {
  font-size: 24px !important;
  line-height: 33.6px !important;
}

.ismob-false-wrapper.is-mob-2-false {
  overflow: hidden;
}

.ismob-false-wrapper.is-mob-2-false .bg-3 {
  left: 80px;
  top: 362px;
  width: 100%;
}

.ismob-false-wrapper.is-mob-2-true .bg-3 {
  left: 0;
  top: 374px;
  width: 390px;
}

.ismob-false-wrapper.is-mob-2-false .bg-texture {
  height: 90px;
  left: -50px;
  top: 128px;
  width: 100%;
}

.ismob-false-wrapper.is-mob-2-true .bg-texture {
  height: 108px;
  left: 12px;
  top: 120px;
  width: 108px;
}
