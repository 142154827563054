.hero-section {
  position: relative;
  margin-left: -160px;
  margin-right: -160px;
  padding-bottom: 96px;
}

.hero-section .image {
  height: 800px;
  min-width: 1280px;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
}

.hero-section .cover-image {
  height: 800px;
  width: 100%;
  background-image: url('../../../public/assets/hero-bg.png');
  background-size: cover;
  background-position: center;
  will-change: transform;
  object-fit: cover;
  position: relative;
}

.hero-section .hero-desciption {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 180px;
  position: absolute;
  top: 470px;
  width: 1040px;
}

.hero-section .hero-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 550px;
}

.hero-section .p {
  align-self: stretch;
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
}

.hero-section .element-4 {
  align-self: stretch;
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.4px;
  position: relative;
}

.hero-section .statistics-instance {
  width: 460px !important;
}

.hero-section .instance-node {
  font-size: 32px !important;
  line-height: 44.8px !important;
}

.hero-section .statistics-2 {
  font-size: 16px !important;
  line-height: 22.4px !important;
}

.hero-section .statistics-3 {
  font-size: 16px !important;
  line-height: 22.4px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.hero-section .statistics-4 {
  font-size: 16px !important;
  line-height: 22.4px !important;
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}

.hero-section .hero-desciption-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 392px;
  padding: 0px 24px;
  position: relative;
  top: 260px;
  width: 390px;
}

.hero-section .hero-text-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.hero-section .element-5 {
  align-self: stretch;
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 33.6px;
  margin-top: -1px;
  position: relative;
}

.hero-section .element-6 {
  align-self: stretch;
  color: var(--gray-50);
  font-family: "Noto Sans Georgian", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  position: relative;
}

.hero-section .statistics-5 {
  gap: 3.04px !important;
  width: 77.52px !important;
}

.hero-section .statistics-6 {
  border-top-width: 0.76px !important;
  padding: 15.2px 0px 0px !important;
}

.hero-section .statistics-7 {
  height: 36.48px !important;
  min-width: 60.8px !important;
}

.hero-section .statistics-8 {
  gap: 18.24px !important;
  padding: 0px 0px 2.9e-6px !important;
  width: 342px !important;
}

.hero-section .statistics-9 {
  font-size: 24.3px !important;
  line-height: 34px !important;
  margin-top: -0.76px !important;
}

.hero-section .statistics-10 {
  font-size: 10.6px !important;
  line-height: 14.9px !important;
}

.hero-section .statistics-11 {
  font-size: 10.6px !important;
  line-height: 14.9px !important;
  margin-left: -1.24px !important;
  margin-right: -1.24px !important;
}

.hero-section .statistics-12 {
  font-size: 10.6px !important;
  line-height: 14.9px !important;
  margin-left: -0.24px !important;
  margin-right: -0.24px !important;
}

.hero-section.is-mob-false {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.hero-section.is-mob-true {
  background-image: url("../../../public/assets/hero-bg.png");
  background-size: 100% 100%;
  background-size: cover;
  height: 700px;
  width: 100%;
}
