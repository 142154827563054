.base-input {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
  width: 270px;
}

.base-input .label {
  color: var(--gray-1000);
  font-family: var(--label-font-family);
  font-size: var(--label-font-size);
  font-style: var(--label-font-style);
  font-weight: var(--label-font-weight);
  letter-spacing: var(--label-letter-spacing);
  line-height: var(--label-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.base-input .input-field {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray-100);
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 3px;
  display: flex;
  height: 40px;
  padding: 8px 12px;
  position: relative;
}

.base-input .icon {
  background-image: url(https://generation-sessions.s3.amazonaws.com/5dfcc5d69cc66bba85ef251c08b40128/img/chevron-1.svg);
  background-size: 100% 100%;
  height: 16px;
  min-width: 16px;
  position: relative;
}
